<template>
  <b-overlay
    :show="loading"
    style="min-height: 20vw; width: 100%; align-self:start; margin-top: 12px"
    opacity="0.5"
  >
    <div
      class="faqs-container"
    >
      <b-row
        class="faq-question"
      >
        <b-col
          cols="12"
          class="faqs-list"
        >
          <div
            v-if="open && faqs"
            class="faq-item align-items-end justify-content-end"
            style="border-bottom-left-radius: 0; border-bottom-right-radius: 0"
          >
            <div
              class="icon"
              @click="toggleAll"
            >
              {{ open.length !== Object.keys(faqs).length ? 'Ver tudo' : 'Ocultar' }}
            </div>
          </div>
        </b-col>
        <b-col
          cols="12"
          md="12"
          class="faqs-list"
        >
          <div
            v-for="(item, i) in faqs"
            :key="i"
            class="faq-item"
            :style="i === 0 ?
              'border-top-left-radius: 0; border-top-right-radius: 0' : ''"
          >
            <div class="title">
              {{ item.question }}
            </div>
            <div class="icon">
              <b-icon
                :icon="open.includes( item.id) ? 'slash-circle-fill' : 'plus-circle-fill'"
                :rotate="open.includes( item.id) ? '45' : 0"
                @click="toggleOpen(item.id)"
              />
            </div>
            <div
              v-if="open.includes(item.id)"
              class="description"
            >
              <p
                v-for="(desc, k) in item.answer.split('\n')"
                :key="k"
              >
                <span v-html="desc" />
              </p>
            </div>
          </div>
        </b-col>
      </b-row>
    </div>
  </b-overlay>
</template>
<script>
import { get } from '../../services/api/index';

export default {
  name: 'DashboardFaq',
  props: {
    setState: {
      type: Function,
      default: null,
    },
  },
  data() {
    return {
      faqs: null,
      open: [],
      loading: true,
    };
  },

  async created() {
    this.loading = true;
    await get('faqs').then(
      (response) => {
        this.faqs = response.data;
        if (response.data.length > 0) {
          this.open = [response.data[0].id];
        }
      },
    );
    this.loading = false;
  },
  methods: {
    toggleOpen(open) {
      if (this.open.includes(open)) {
        this.open = this.open.filter((el) => el !== open);
      } else {
        this.open.push(open);
      }
    },
    toggleAll() {
      if (this.open.length !== Object.keys(this.faqs).length) {
        this.open = [];
        Object.keys(this.faqs).forEach((el, i) => {
          this.open.push(this.faqs[i].id);
        });
      } else {
        this.open = [];
      }
    },
  },

};
</script>
